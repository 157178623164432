@import url('https://rsms.me/inter/inter.css');
@import "~react-vis/dist/style.css";

* {
  box-sizing: border-box;
  outline: none;
}

html, body, .h100 {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #292C36;
  color: #292C36;
  overflow: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 5px
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 5px
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc; 
  border-radius: 5px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1, h2, h3, p {
	margin: 0;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

table {
  border-collapse: collapse;
}

.app-container {
  display: flex;
  flex-direction: column;
}

.pointer {
  cursor: pointer;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.none {
  display: none;
}

.pull-left {
  float: left;
}

.btn {
  border-radius: 2px;
  background-color: #FF8900;
  box-shadow: 0 2px 7px 0 rgba(255,137,0,0.12);
  padding: 13px 40px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  border: none;
  cursor: pointer;
  transition: all .4s;
}

.btn:hover {
  background-color: #DD7700;
  transition: all .4s;
}

.btn--white {
  border: 1px solid rgba(142, 146, 159, .3);
  border-radius: 2px;
  /* box-shadow: 0 2px 7px 0 rgba(255,0,37,0.09); */
  box-shadow: none;
  background-color: transparent!important;
  color: #8E929F;
}

.btn--red {
  background-color: #FF0025!important;
  box-shadow: 0 2px 7px 0 rgba(255,0,37,0.09);
}

.btn--gray {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, .1)!important;
  box-shadow: none;
  /* box-shadow: 0 2px 7px 0 rgba(255,137,0,0.12); */
}

.dropdown-menu {
  display: none;
  opacity: 0;
  background-color: #fff;
  /* transition: opacity .3s ease-in-out; */
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.12);
  margin: 6px 0;
  padding: 7px 24px;
  z-index: 9;
}

.dropdown-menu--noPadding {
  padding-left: 0;
  padding-right: 0;
}

.dropdown-commit {
  margin: 26px 0;
  cursor: none;
}

.show {
  display: block;
  opacity: 1;
  transition: opacity .05s ease-in-out;
}

#popout-content-container {
  height: 100%;
  background-color: #fff;
}