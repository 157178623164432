#userInfo {
  position: absolute;
  top: 0;
  right: 0%;
  width: 100%;
  background-color: rgba(22,23,28,0.85);
  z-index: 300;
  transition: opacity 600ms, visibility 600ms;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  color: #fff;
}

.userInfo__content {
  padding: 38px 45px;
  background-color: #292C36;
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.12);
  position: fixed;
  right: -100%;
  top: 0;
  z-index: 400;
  transition: all .6s;
  width: 70%;
  max-width: 409px;
  overflow: auto;
}

#userInfo.userInfo--opened {
  visibility: visible;
  opacity: 1;
}

.userInfo--opened .userInfo__content {
  right: 0;
  transition: all .5s;
}

.userInfo__header {
  margin-bottom: 50px;
}

.userInfo__title {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
}

.userInfo__right__item {
  margin-left: 20px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 15px;
  position: relative;
}

.userInfo__right__refresh::before {
  content: '';
  background: red;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 0;
  left: 8px;
  border-radius: 100%;
  z-index: 10;
}

.userInfo__icon {
  margin-right: 7px;
}

.userInfo__block {
  margin-bottom: 30px;
}

.userInfo__block__header {
  margin-bottom: 12px;
}

.userInfo__block__title {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 16px;
}

.userInfo__box {
  padding: 29px;
  background-color: rgba(255, 255, 255, .05);
}

.userInfo__box__item {
  margin-top: 25px;
}

.userInfo__box__item:first-child, .userInfo__box__desc li:first-child {
  margin-top: 0;
}

.userInfo__box__title {
  opacity: 0.4;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 14px;
  margin-bottom: 8px;
}

.userInfo__box__desc {
	font-size: 12px;
	font-weight: 600;
  line-height: 15px;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userInfo__box__desc--fullWidth {
  max-width: 100%;
  white-space: normal;
}

.userInfo__box__desc li {
  margin-top: 5px;
}