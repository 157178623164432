.sHeader {
	height: 55px;
	width: 100%;
	background-color: #323640;
	box-shadow: 0 2px 6px 0 rgba(0,0,0,0.01);
	padding: 0 27px 0 15px
}

.sHeader__btn {
	border: 1px solid rgba(255, 137, 0, 0.2);
	border-radius: 2px;
	color: #FF8900;
	font-size: 12px;
	font-weight: 600;
	line-height: 15px;
	margin: 9.5px 5px 9.5px 0;
	padding: 9.5px 17px;
	transition: background-color .6s;
	background-color: transparent;
	box-shadow: none;
}

.sHeader__btn:hover {
	background-color: rgba(255,137,0,0.2);
	transition: background-color .6s;
}

.sHeader__icon {
	margin-left: 8px;
}

.dropdown__item {
	color: #292C36;
	font-size: 12px;
	font-weight: 500;
	line-height: 30px;
	padding: 0 24px;
}

.dropdown__item:hover {
	background-color: rgba(41, 44, 54, 0.05);
}

.dropdown-commit .dropdown-item:last-child {
	margin-left: 5px;
}

.dropdown-commit__title {
	opacity: 0.5;
	color: #292C36;
	font-size: 11px;
	font-weight: 600;
	letter-spacing: 0.57px;
	line-height: 14px;
	cursor: default;
}

.dropdown-commit__textarea {
	height: 75px;
	width: 265px;
	border-radius: 2px;
	background-color: #F5F5F5;
	margin: 14px 0 13px;
	padding: 14px 18px;
	color: #292C35;
	font-size: 12px;
	line-height: 15px;
	resize: none;
	border: none;
}

.sHeader__text {
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	line-height: 23px;
	margin-right: 30px;
}

.sHeader__text--gray {
	font-size: 11px;
	font-weight: 600;
	letter-spacing: 0.25px;
	line-height: 14px;
	margin-right: 11px; 
	color: #fff;
	opacity: .4;
}

.sHeader__text--input input {
	color: #fff;
	border: none;
	border-bottom: 2px solid rgba(255,255,255,.16);
	padding-bottom: 3px;
	margin-top: 3px;
	font-size: 12px;
	font-weight: 600;
	line-height: 15px;
	background: none;
	min-width: 35px;
	max-width: 70px;
}

.sHeader__switch {
	margin-right: 9px;
}

.sHeader__notification {
	position: relative;
	margin-left: 3px;
}

.sHeader__notification__icon__hover {
	padding: 13px 16.5px;
	margin-left: -16.5px;
	transition: all .4s;
}

.sHeader__notification__icon__hover:hover {
	background-color: rgba(255,255,255,.05);
	transition: all .4s;
}

.sHeader__notification__dot {
	height: 16px;
	width: 16px;
	background-color: #FF8900;
	box-shadow: 0 2px 4px 0 rgba(255,137,0,0.16);
	border-radius: 100%;
	position: absolute;
	top: 4px;
	right: 8.5px;
	color: #fff;
	font-size: 9px;
	font-weight: bold;
	line-height: 16px;
}

.sHeader__alert {
	font-size: 12px;
	color: #fff;
	border-radius: 17.5px;
	padding: 7.5px 30px;
	font-weight: 500;
	line-height: 15px;
}

.sHeader__alert p, .sHeader__alert img {
  animation: .3s ease 0s normal forwards 1 fadein;
}

.sHeader__alert--default {
	background-color: rgba(23, 23, 26, 0.84);	
	margin-right: 30px;
}

.sHeader__alert--saved {
	font-size: 13px;
	line-height: 16px;
	padding-top: 0;
	padding-bottom: 0;
	margin-left: 9px;
}

.sHeader__alert--error {
	background-color: #DF0021;
	box-shadow: 0 0 6px 0 rgba(255,0,37,0.06);
	letter-spacing: 0.2px;
	margin-right: 30px;
}

.dropdown-menu--alerts {
	width: 245px;
	padding: 0;
}

.alerts__container {
	max-height: 290px;
	overflow: auto;
}

.dropdown__item--alerts {
  padding: 17px 27px;
  opacity: 0.5;
  color: #292C36;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.62px;
  line-height: 15px;
  margin-bottom: 2px;
  cursor: default;
	background: transparent!important;
}

.dropdown__item--clear {
  color: #FF8900;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  padding: 17px 27px;
  background: transparent!important;
}

.dropdown__item--alert {
	border-top: 1px solid rgba(41, 44, 54, 0.05);
	padding: 17px 22px 17px 27px;
	color: #292C36;
	font-size: 13px;
	font-weight: 500;
	line-height: 16px;
	cursor: default;
	flex: 1 1 auto;
}

.sHeader__notification__remove {
	padding: 7px;
	box-sizing: content-box;
}

/*.react-switch-bg {
	background: #FF8900!important;
}
*/
@keyframes fadein {
  0% { opacity: 0; }
  35% { opacity: .35; }
  50% { opacity: .5; }
  75% { opacity: .75; }
  100% { opacity: 1; }
}