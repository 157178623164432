#sidebar {
	flex-basis: 20%;
	max-width: 20%;
	background-color: #191A23;
	box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
	padding: 20.5px 0;
	overflow: auto;
	height: 100%;
	overflow-x: hidden;
}

.sidebar__content {
	flex: 1 1 auto;
}

.splitter-layout {
	position: static;
}

.sidebar__scroll {
	overflow: auto;
	height: calc(100% - 25px);
}

.splitter-layout .layout-pane {
	display: flex;
	min-height: 24px;
}

.splitter-layout > .layout-splitter {
	padding: 24px 0;
	width: 100%;
	margin-bottom: 20px;
	position: relative;
	background-color: transparent!important;
}

.splitter-layout > .layout-splitter:after {
	content: '';
	position: absolute;
	left: 50%;
	margin-left: -13px;
	top: 50%;
	margin-top: -1.7px;
	height: 3.48px;
	width: 26px;
	opacity: 0.1;
	border-radius: 3.5px;
	background-color: #fff;
}

.sidebar__header {
	margin-bottom: 10px;
}

.sidebar__title {
	text-transform: uppercase;
	color: rgba(255,255,255,0.25);
	font-size: 11px;
	font-weight: 600;
	letter-spacing: 0.57px;
	line-height: 14px;
	padding: 0 25px;
}

.sidebar__library__searchContent {
	margin-right: 27.5px;
	opacity: .25;
}

.sidebar__library__searchContent--active {
	opacity: 1;
}

.sidebar__library__icon {
	object-fit: contain;
}

#sidebar__library__search {
	background: transparent;
	border: none;
	border-bottom: 2px solid transparent;
	transition: all .4s;
	color: #fff;
	width: 0px!important;
	height: 14px;
}

.sidebar__library__searchContent--active #sidebar__library__search,
#sidebar__library__search:focus {
	width: 82px!important;
	border-bottom: 2px solid rgba(255,255,255,.19);
	transition: all .4s;
	opacity: 1;
	margin-left: 8px;
}

.sidebar__item__content {
	/*padding-left: 24px;*/
	margin-right: 24px;
	/* width: 100%; */
}

.sidebar__item__submenu {
	overflow: hidden;
}

.sidebar__item__hover {
	transition: all .4s;
	width: 100%;
	flex: 1 1 auto;
	flex-basis: 100%;
	padding: 10px 27.5px 10px 24px;
}

.sidebar__item__submenu:nth-child(2n) .sidebar__item__files {
	padding-left: 59px;
}

.sidebar__item__submenu:nth-child(3n) .sidebar__item__files {
	padding-left: 73px;
}

.sidebar__item__submenu:nth-child(4n) .sidebar__item__files {
	padding-left: 87px;
}


.sidebar__item__hover--margin {
	/*margin-left: -24px;*/
}

.sidebar__item__hover:hover {
	background-color: rgba(255,255,255,0.06);
	transition: all .4s;
}

.sidebar__item__hover:hover .sidebar__item__dots {
	opacity: 1;
	transition: all .4s;
}

.sidebar__item__dots {
	opacity: .2;
	transition: all .4s;
}

.sidebar__item__files {
	padding: 6.5px 27.5px 6.5px 44px;
	/*margin-left: -24px;*/
}

.sidebar__item__arrow {
	margin-right: 15px;
	transition: all .4s;
}

.collapsed .sidebar__item__arrow, .collapsing .sidebar__item__arrow {
	transform: rotate(-90deg);
	transition: all .4s;
}

.sidebar__item__name {
	color: #fff;
	font-size: 13px;
	line-height: 16px;
	margin-left: 12px;
	width: 80%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.dropdown-details {
	padding: 3px 21px 20px;
	margin-top: 80px;
	min-width: 129px;
	cursor: default;
}

.dropdown-details__content {
	margin-top: 13px;
}

.dropdown-details__text {
	font-size: 13px;
	line-height: 20px;
}

.dropdown-details__text--title {
	font-weight: 600;
}

.sidebar__pip {
	height: 287px;
	width: 300px;
	border-radius: 2px;
	background-color: #fff;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14);
	bottom: 6px;
	right: 9px;
	z-index: 100;
	padding: 14px 22px 28px;
}

.sidebar__pip__header {
	margin-bottom: 14px;
}

.sidebar__pip__name {
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
}

.sidebar__pip__export {
	margin-right: 15.5px;
}

@media only screen and (min-width: 1500px) {
	#sidebar {
	  flex-basis: 15%;
	  max-width: 15%;
	}
}