.header {
	height: 53px;
	background-color: #191A23;
	box-shadow: -5px 0 29px 0 rgba(0,0,0,0);
	width: 100%;
	padding: 0 22px;
}

.header__logo {
	margin-right: 36px;
}

.header__section__container {
	flex-basis: 70%;
	max-width: 70%;
}

.header__section {
	background-color: rgba(50, 54, 64, 0.35);
	padding: 0 18px;
	cursor: pointer;
	color: #fff;
	font-size: 13px;
	font-weight: 600;
	letter-spacing: 0.17px;
	line-height: 16px;
	transition: background-color .6s;
	max-width: 153px;
	flex: 1 1 auto;
}

.header__section p {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.header__section--active {
	box-shadow: -2px 0 0 0 #FF8900;
	background-color: #323640;
	cursor: default;
	/*padding-right: 18px;*/
}

.header__section--active .header__section__close {
	display: block;
}

.header__section__close {
	cursor: pointer;
	/*margin-left: 25px;*/
	padding-left: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	box-sizing: content-box;
}

.header__section__icon {
	margin-right: 9.5px;
}

.header__hover:hover {
	background-color: rgba(255, 255, 255, 0.05);
	transition: background-color .6s;
}

.header__btn {
	border-radius: 2px;
	padding: 12px 15.5px;
	color: #FF8900;
	font-size: 13px;
	font-weight: 600;
	line-height: 16px;
	margin: 6.5px 12px;
	cursor: pointer;
	transition: all .4s;
}

.header__btn:hover {
	background-color: rgba(255, 137, 0, 0.05);
}

.header__btn__add {
	margin-right: 6px;
}

.header__info {
	padding: 13px 17px;
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	line-height: 16px;
	margin: 6.5px 3px;
	cursor: pointer;
	text-decoration: none;
}

.header__info:first-child .header__info__icon {
	margin-top: 1px;
}

.header__info__icon {
	margin-right: 12px;
}