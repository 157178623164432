@import url(https://rsms.me/inter/inter.css);
* {
  box-sizing: border-box;
  outline: none;
}

html, body, .h100 {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #292C36;
  color: #292C36;
  overflow: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 5px
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 5px
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc; 
  border-radius: 5px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1, h2, h3, p {
	margin: 0;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

table {
  border-collapse: collapse;
}

.app-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.pointer {
  cursor: pointer;
}

.flex {
  display: -webkit-flex;
  display: flex;
}

.none {
  display: none;
}

.pull-left {
  float: left;
}

.btn {
  border-radius: 2px;
  background-color: #FF8900;
  box-shadow: 0 2px 7px 0 rgba(255,137,0,0.12);
  padding: 13px 40px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  border: none;
  cursor: pointer;
  -webkit-transition: all .4s;
  transition: all .4s;
}

.btn:hover {
  background-color: #DD7700;
  -webkit-transition: all .4s;
  transition: all .4s;
}

.btn--white {
  border: 1px solid rgba(142, 146, 159, .3);
  border-radius: 2px;
  /* box-shadow: 0 2px 7px 0 rgba(255,0,37,0.09); */
  box-shadow: none;
  background-color: transparent!important;
  color: #8E929F;
}

.btn--red {
  background-color: #FF0025!important;
  box-shadow: 0 2px 7px 0 rgba(255,0,37,0.09);
}

.btn--gray {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, .1)!important;
  box-shadow: none;
  /* box-shadow: 0 2px 7px 0 rgba(255,137,0,0.12); */
}

.dropdown-menu {
  display: none;
  opacity: 0;
  background-color: #fff;
  /* transition: opacity .3s ease-in-out; */
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.12);
  margin: 6px 0;
  padding: 7px 24px;
  z-index: 9;
}

.dropdown-menu--noPadding {
  padding-left: 0;
  padding-right: 0;
}

.dropdown-commit {
  margin: 26px 0;
  cursor: none;
}

.show {
  display: block;
  opacity: 1;
  -webkit-transition: opacity .05s ease-in-out;
  transition: opacity .05s ease-in-out;
}

#popout-content-container {
  height: 100%;
  background-color: #fff;
}
.container-fluid,
.container {
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  padding-right: 2rem;
  padding-left: 2rem;
}

.row {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.row.reverse {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.col.reverse {
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
  box-sizing: border-box;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
}

.col-xs {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-basis: 0;
          flex-basis: 0;
  max-width: 100%;
}

.col-xs-1 {
  -webkit-flex-basis: 8.33333333%;
          flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.col-xs-2 {
  -webkit-flex-basis: 16.66666667%;
          flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.col-xs-3 {
  -webkit-flex-basis: 25%;
          flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  -webkit-flex-basis: 33.33333333%;
          flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.col-xs-5 {
  -webkit-flex-basis: 41.66666667%;
          flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.col-xs-6 {
  -webkit-flex-basis: 50%;
          flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  -webkit-flex-basis: 58.33333333%;
          flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.col-xs-8 {
  -webkit-flex-basis: 66.66666667%;
          flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.col-xs-9 {
  -webkit-flex-basis: 75%;
          flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  -webkit-flex-basis: 83.33333333%;
          flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.col-xs-11 {
  -webkit-flex-basis: 91.66666667%;
          flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.col-xs-12 {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.start-xs {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  text-align: start;
}

.center-xs {
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

.end-xs {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  text-align: end;
}

.top-xs {
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.middle-xs {
  -webkit-align-items: center;
          align-items: center;
}

.bottom-xs {
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.around-xs {
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.between-xs {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.first-xs {
  -webkit-order: -1;
          order: -1;
}

.last-xs {
  -webkit-order: 1;
          order: 1;
}

@media only screen and (min-width: 48em) {
  .container {
    width: 49rem;
  }

  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-offset-0,
  .col-sm-offset-1,
  .col-sm-offset-2,
  .col-sm-offset-3,
  .col-sm-offset-4,
  .col-sm-offset-5,
  .col-sm-offset-6,
  .col-sm-offset-7,
  .col-sm-offset-8,
  .col-sm-offset-9,
  .col-sm-offset-10,
  .col-sm-offset-11,
  .col-sm-offset-12 {
    box-sizing: border-box;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-sm {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-1 {
    -webkit-flex-basis: 8.33333333%;
            flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    -webkit-flex-basis: 16.66666667%;
            flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    -webkit-flex-basis: 25%;
            flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-flex-basis: 33.33333333%;
            flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    -webkit-flex-basis: 41.66666667%;
            flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    -webkit-flex-basis: 50%;
            flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-flex-basis: 58.33333333%;
            flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    -webkit-flex-basis: 66.66666667%;
            flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    -webkit-flex-basis: 75%;
            flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-flex-basis: 83.33333333%;
            flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    -webkit-flex-basis: 91.66666667%;
            flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-sm {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    text-align: start;
  }

  .center-sm {
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
  }

  .end-sm {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    text-align: end;
  }

  .top-sm {
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .middle-sm {
    -webkit-align-items: center;
            align-items: center;
  }

  .bottom-sm {
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }

  .around-sm {
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }

  .between-sm {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .first-sm {
    -webkit-order: -1;
            order: -1;
  }

  .last-sm {
    -webkit-order: 1;
            order: 1;
  }
}

@media only screen and (min-width: 64em) {
  .container {
    width: 65rem;
  }

  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-offset-0,
  .col-md-offset-1,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12 {
    box-sizing: border-box;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-md {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1 {
    -webkit-flex-basis: 8.33333333%;
            flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    -webkit-flex-basis: 16.66666667%;
            flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    -webkit-flex-basis: 25%;
            flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-flex-basis: 33.33333333%;
            flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    -webkit-flex-basis: 41.66666667%;
            flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    -webkit-flex-basis: 50%;
            flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-flex-basis: 58.33333333%;
            flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    -webkit-flex-basis: 66.66666667%;
            flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    -webkit-flex-basis: 75%;
            flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-flex-basis: 83.33333333%;
            flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    -webkit-flex-basis: 91.66666667%;
            flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    max-width: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }

  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-md {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
  }

  .end-md {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    text-align: end;
  }

  .top-md {
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .middle-md {
    -webkit-align-items: center;
            align-items: center;
  }

  .bottom-md {
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }

  .around-md {
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }

  .between-md {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .first-md {
    -webkit-order: -1;
            order: -1;
  }

  .last-md {
    -webkit-order: 1;
            order: 1;
  }
}

@media only screen and (min-width: 75em) {
  .container {
    width: 76rem;
  }

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-0,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    box-sizing: border-box;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-lg {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-1 {
    -webkit-flex-basis: 8.33333333%;
            flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    -webkit-flex-basis: 16.66666667%;
            flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    -webkit-flex-basis: 25%;
            flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-flex-basis: 33.33333333%;
            flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    -webkit-flex-basis: 41.66666667%;
            flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    -webkit-flex-basis: 50%;
            flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-flex-basis: 58.33333333%;
            flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    -webkit-flex-basis: 66.66666667%;
            flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    -webkit-flex-basis: 75%;
            flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-flex-basis: 83.33333333%;
            flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    -webkit-flex-basis: 91.66666667%;
            flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-lg {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
  }

  .end-lg {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    text-align: end;
  }

  .top-lg {
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .middle-lg {
    -webkit-align-items: center;
            align-items: center;
  }

  .bottom-lg {
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }

  .around-lg {
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }

  .between-lg {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .first-lg {
    -webkit-order: -1;
            order: -1;
  }

  .last-lg {
    -webkit-order: 1;
            order: 1;
  }
}
.modal__box__title {
	color: #FF0025;
	font-size: 19px;
	font-weight: 500;
	line-height: 22px;
}

.modal__box__title--gray {
	color: #292C36;
}

.modal__box__desc {
	color: #8E929F;
	font-size: 14px;
	line-height: 24px;
	margin: 9px 0 28px;
}

.modal__box {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); 
  z-index: 500;
  max-width: 425px;
  width: 70%;
  position: fixed;
  background-color: #fff;
  padding: 21px 0 56px;
}

.modal__close--gray {
	opacity: 1!important;
	margin-right: 21px;
}

.modal__box__content {
	max-width: 300px;
	margin: 24px auto 0;
}

.modal__box__btns .btn {
	padding: 11px 23.5px;
	max-width: 49%;
}

#modal__box__input {
	width: 100%;
	border-radius: 2px;
	background-color: #F3F3F3;
	padding: 13px 17px;
	color: rgba(41, 44, 54, .5);
	font-size: 12px;
	line-height: 15px;
	border: none;
	margin: 19px 0 29px;
}
.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 400;
  -webkit-animation: .2s ease 0s normal forwards 1 fadein;
          animation: .2s ease 0s normal forwards 1 fadein;
}

.modal__fade {
  background-color: rgba(22,23,28,0.85);
  width: 100%;
  z-index: 400;
  top: 0;
  left: 0;
  position: fixed;
}

.modal__close {
  opacity: .2;
  padding: 5px;
  box-sizing: content-box;
}

.modal--session__box {
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin-top: -263px;
  z-index: 500;
  max-width: 839px;
  width: 90%;
  position: fixed;
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.02);
  background-color: transparent;
  padding: 0;
}

.modal--session__box__left {
  padding: 14px 13px 69px;
  background-color: #292C36;
}

.modal--session__box__right {
  border-radius: 0 2px 2px 0;
  background-color: #1E2028;
  padding: 26px 0 14px;
}

.modal--session__box__content {
  padding: 20px 47px 0;
}

.modal__logo {
  margin-bottom: 24px;
}

.modal__title {
  color: #fff;
  font-size: 27px;
  font-weight: 600;
  line-height: 32px;
}

.modal__desc {
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  margin: 10px 0 46px;
}

.modal__title--gray {
  color: rgba(255,255,255,0.25);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.62px;
  line-height: 15px;
  margin-bottom: 18px;
}

.modal__btn--session {
  max-width: 162px;
  border-radius: 2px;
  background-color: #fff;
  padding: 14px 23px;
  color: #EA7E00;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 6px;
}

.dropdown__item--modal {
  margin-top: 5px;
}

.dropdown__item--modal .dropdown__item {
  padding: 0 36px 0 23px;
  font-weight: 400;
}

.dropdown__item--templates {
  margin-top: -47px;
  min-width: 244px;
}

.dropdown__item--templates .dropdown__item {
  padding: 15px 24px;
  border-top: 1px solid rgba(41, 44, 54, .05);
  line-height: 16px;
}

.dropdown__item--templates .dropdown__item:first-of-type {
  border: none;
}

.dropdown__title {
  opacity: 0.5;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.62px;
  line-height: 15px;
}

.dropdown__arrow {
  margin-right: 12px;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.modal__link {
  color: #fff;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 24.5px;
  font-size: 14px;
  text-decoration: none;
  -webkit-transition: all .5s;
  transition: all .5s;
}

/*.modal__link:hover {
  opacity: .75;
  transition: all .8s;
}*/

.modal__link img {
  margin-right: 13px;
}

.modal__title--right {
  padding: 0 30px 0 30px;
}

.modal__used {
  padding: 13px 30px;
  -webkit-transition: all .4s;
  transition: all .4s;
}

.modal__used:hover {
  background-color: #272830;
}

.modal__used__name {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-left: 16px;
}

.modal__select {
  color: #FF8900;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.43px;
  line-height: 16px;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
}

.modal__icon__upload {
  margin-right: 8px;
}
#storage, .fade {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 300;
  overflow: auto;
  visibility: hidden;
}

.fade {
  background-color: rgba(22,23,28,0.85);
  opacity: 0;
  -webkit-transition: all .4s;
  transition: all .4s;
}

.storage__content {
  width: 85%;
  max-width: 864px;
  padding: 29px 0 39px;
  background-color: #292C36;
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.12);
  position: fixed;
  right: -100%;
  top: 0;
  z-index: 400;
  color: #fff;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  /* animation: .4s ease 0s normal forwards 1 slideLeft; */
}

#storage.storage--opened, #storage.storage--opened .fade {
  visibility: visible;
  opacity: 1;
}

.storage--opened .storage__content {
  right: 0;
  -webkit-transition: all .6s;
  transition: all .6s;
}

.storage__close__icon {
  position: absolute;
  left: -105px;
  top: 0;
  padding: 43px 44px;
	-webkit-transform: rotate(-180deg);
	        transform: rotate(-180deg)
}

.storage__header {
  margin-bottom: 36px;
  padding: 0 50px;
  min-height: 41px;
}

.storage__title {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  opacity: .25;
  -webkit-transition: all .4s;
  transition: all .4s;
}

.storage__breadcrumb:last-child .storage__title {
  opacity: 1;
}

.storage__folders__toggle {
  background-color: rgba(255, 255, 255, .05);
  padding: 12px 14px;
}

.dropdown-menu--breadcrumb {
  padding: 0;
}

.dropdown-menu--breadcrumb .dropdown__item--breadcrumb {
  padding: 11.5px 29px 11.5px 24px!important;
  line-height: 16px;
}

.dropdown__item--breadcrumb:first-child, .dropdown__item--breadcrumb:last-child {
  display: none;
}

.storage__folders__icon {
  margin-left: 15px;
}

.storage__header__right__items, .storage__search {
  margin-left: 26px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 15px;
  color: #fff;
}

.storage__search {
  background-color: transparent;
  border: none;
  margin-left: 0;
  width: 46px;
  line-height: 1;
  -webkit-transition: all .4s;
  transition: all .4s;
}

.storage__search::-webkit-input-placeholder {
  color: #fff;
}

.storage__search:-ms-input-placeholder {
  color: #fff;
}

.storage__search::-ms-input-placeholder {
  color: #fff;
}

.storage__search::placeholder {
  color: #fff;
}

.storage__search:focus {
  width: 80px;
}

.storage__header__icon {
  margin-right: 8px;
}

.storage__table--container {
  height: 370px;
  overflow: auto;
}

.storage__table {
  width: 100%;
}

.storage__table tr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  min-height: 57px;
}

.storage__table__selected {
  opacity: 1!important;
}

.storage__table__selected * {
  display: inline-block;
}

.storage__table__selected img {
  margin: 2.5px 5px;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.storage__table__selected .orderASC {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

.storage__table td {
  padding: 19px 0;
}

.storage__table td:first-child {
  padding-left: 50px;
}

.storage__table td:last-child {
  padding-right: 50px;
}

.storage__table thead td {
  opacity: 0.5;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 14px;
  -webkit-transition: all .4s;
  transition: all .4s;
}

.storage__table thead td:hover {
  opacity: 1;
  -webkit-transition: all .4s;
  transition: all .4s;
}

.storage__table tbody tr {
  background-color: transparent;
  -webkit-transition: all .4s;
  transition: all .4s;
}

.storage__table tbody tr:hover, .tr--selected {
  background-color: rgba(255,255,255,0.06)!important;
  -webkit-transition: all .4s;
  transition: all .4s;
}

.storage__table tbody tr:hover .storage__table__options {
  opacity: 1;
  -webkit-transition: all .4s;
  transition: all .4s;
}

.storage__table tbody td, .storage__rename {
  color: rgba(255, 255, 255, .5);
  font-size: 13px;
  line-height: 16px;
}

.storage__table tbody td:first-child {
  color: #fff
}

.storage__table tbody td:last-child {
  padding: 8px 50px 8px 0;
}

.dropdown-menu--table {
  text-align: left;
  z-index: 400;
}

.dropdown-menu--table .dropdown__item {
  padding: 0 29px 0 24px;
}

.storage__table__options {
  padding: 10px;
  opacity: .5;
}

.storage__breadcrumb__icon {
  margin: 0 15px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  opacity: .25;
}

.storage__breadcrumb:last-child .storage__breadcrumb__icon {
  display: none;
}

.storage__name {
  border-bottom: 1px solid transparent;
}

.storage__rename {
  border: none;
  border-bottom: 1px dashed rgba(255, 255, 255, .2);
  background: transparent;
  color: #fff;
}

.storage__save__text {
  color: #FFFFFF;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 45px;
  margin-left: 50px;
}

.storage__save__buttons {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  margin-left: 50px;
}

.storage__save__buttons .btn {
  margin-right: 14px;
}

@media (min-height: 800px) {
  .storage__table--container {
    height: 600px;
  }
}
#userInfo {
  position: absolute;
  top: 0;
  right: 0%;
  width: 100%;
  background-color: rgba(22,23,28,0.85);
  z-index: 300;
  -webkit-transition: opacity 600ms, visibility 600ms;
  transition: opacity 600ms, visibility 600ms;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  color: #fff;
}

.userInfo__content {
  padding: 38px 45px;
  background-color: #292C36;
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.12);
  position: fixed;
  right: -100%;
  top: 0;
  z-index: 400;
  -webkit-transition: all .6s;
  transition: all .6s;
  width: 70%;
  max-width: 409px;
  overflow: auto;
}

#userInfo.userInfo--opened {
  visibility: visible;
  opacity: 1;
}

.userInfo--opened .userInfo__content {
  right: 0;
  -webkit-transition: all .5s;
  transition: all .5s;
}

.userInfo__header {
  margin-bottom: 50px;
}

.userInfo__title {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
}

.userInfo__right__item {
  margin-left: 20px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 15px;
  position: relative;
}

.userInfo__right__refresh::before {
  content: '';
  background: red;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 0;
  left: 8px;
  border-radius: 100%;
  z-index: 10;
}

.userInfo__icon {
  margin-right: 7px;
}

.userInfo__block {
  margin-bottom: 30px;
}

.userInfo__block__header {
  margin-bottom: 12px;
}

.userInfo__block__title {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 16px;
}

.userInfo__box {
  padding: 29px;
  background-color: rgba(255, 255, 255, .05);
}

.userInfo__box__item {
  margin-top: 25px;
}

.userInfo__box__item:first-child, .userInfo__box__desc li:first-child {
  margin-top: 0;
}

.userInfo__box__title {
  opacity: 0.4;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 14px;
  margin-bottom: 8px;
}

.userInfo__box__desc {
	font-size: 12px;
	font-weight: 600;
  line-height: 15px;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userInfo__box__desc--fullWidth {
  max-width: 100%;
  white-space: normal;
}

.userInfo__box__desc li {
  margin-top: 5px;
}
.header {
	height: 53px;
	background-color: #191A23;
	box-shadow: -5px 0 29px 0 rgba(0,0,0,0);
	width: 100%;
	padding: 0 22px;
}

.header__logo {
	margin-right: 36px;
}

.header__section__container {
	-webkit-flex-basis: 70%;
	        flex-basis: 70%;
	max-width: 70%;
}

.header__section {
	background-color: rgba(50, 54, 64, 0.35);
	padding: 0 18px;
	cursor: pointer;
	color: #fff;
	font-size: 13px;
	font-weight: 600;
	letter-spacing: 0.17px;
	line-height: 16px;
	-webkit-transition: background-color .6s;
	transition: background-color .6s;
	max-width: 153px;
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
}

.header__section p {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.header__section--active {
	box-shadow: -2px 0 0 0 #FF8900;
	background-color: #323640;
	cursor: default;
	/*padding-right: 18px;*/
}

.header__section--active .header__section__close {
	display: block;
}

.header__section__close {
	cursor: pointer;
	/*margin-left: 25px;*/
	padding-left: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	box-sizing: content-box;
}

.header__section__icon {
	margin-right: 9.5px;
}

.header__hover:hover {
	background-color: rgba(255, 255, 255, 0.05);
	-webkit-transition: background-color .6s;
	transition: background-color .6s;
}

.header__btn {
	border-radius: 2px;
	padding: 12px 15.5px;
	color: #FF8900;
	font-size: 13px;
	font-weight: 600;
	line-height: 16px;
	margin: 6.5px 12px;
	cursor: pointer;
	-webkit-transition: all .4s;
	transition: all .4s;
}

.header__btn:hover {
	background-color: rgba(255, 137, 0, 0.05);
}

.header__btn__add {
	margin-right: 6px;
}

.header__info {
	padding: 13px 17px;
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	line-height: 16px;
	margin: 6.5px 3px;
	cursor: pointer;
	text-decoration: none;
}

.header__info:first-child .header__info__icon {
	margin-top: 1px;
}

.header__info__icon {
	margin-right: 12px;
}
.sHeader {
	height: 55px;
	width: 100%;
	background-color: #323640;
	box-shadow: 0 2px 6px 0 rgba(0,0,0,0.01);
	padding: 0 27px 0 15px
}

.sHeader__btn {
	border: 1px solid rgba(255, 137, 0, 0.2);
	border-radius: 2px;
	color: #FF8900;
	font-size: 12px;
	font-weight: 600;
	line-height: 15px;
	margin: 9.5px 5px 9.5px 0;
	padding: 9.5px 17px;
	-webkit-transition: background-color .6s;
	transition: background-color .6s;
	background-color: transparent;
	box-shadow: none;
}

.sHeader__btn:hover {
	background-color: rgba(255,137,0,0.2);
	-webkit-transition: background-color .6s;
	transition: background-color .6s;
}

.sHeader__icon {
	margin-left: 8px;
}

.dropdown__item {
	color: #292C36;
	font-size: 12px;
	font-weight: 500;
	line-height: 30px;
	padding: 0 24px;
}

.dropdown__item:hover {
	background-color: rgba(41, 44, 54, 0.05);
}

.dropdown-commit .dropdown-item:last-child {
	margin-left: 5px;
}

.dropdown-commit__title {
	opacity: 0.5;
	color: #292C36;
	font-size: 11px;
	font-weight: 600;
	letter-spacing: 0.57px;
	line-height: 14px;
	cursor: default;
}

.dropdown-commit__textarea {
	height: 75px;
	width: 265px;
	border-radius: 2px;
	background-color: #F5F5F5;
	margin: 14px 0 13px;
	padding: 14px 18px;
	color: #292C35;
	font-size: 12px;
	line-height: 15px;
	resize: none;
	border: none;
}

.sHeader__text {
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	line-height: 23px;
	margin-right: 30px;
}

.sHeader__text--gray {
	font-size: 11px;
	font-weight: 600;
	letter-spacing: 0.25px;
	line-height: 14px;
	margin-right: 11px; 
	color: #fff;
	opacity: .4;
}

.sHeader__text--input input {
	color: #fff;
	border: none;
	border-bottom: 2px solid rgba(255,255,255,.16);
	padding-bottom: 3px;
	margin-top: 3px;
	font-size: 12px;
	font-weight: 600;
	line-height: 15px;
	background: none;
	min-width: 35px;
	max-width: 70px;
}

.sHeader__switch {
	margin-right: 9px;
}

.sHeader__notification {
	position: relative;
	margin-left: 3px;
}

.sHeader__notification__icon__hover {
	padding: 13px 16.5px;
	margin-left: -16.5px;
	-webkit-transition: all .4s;
	transition: all .4s;
}

.sHeader__notification__icon__hover:hover {
	background-color: rgba(255,255,255,.05);
	-webkit-transition: all .4s;
	transition: all .4s;
}

.sHeader__notification__dot {
	height: 16px;
	width: 16px;
	background-color: #FF8900;
	box-shadow: 0 2px 4px 0 rgba(255,137,0,0.16);
	border-radius: 100%;
	position: absolute;
	top: 4px;
	right: 8.5px;
	color: #fff;
	font-size: 9px;
	font-weight: bold;
	line-height: 16px;
}

.sHeader__alert {
	font-size: 12px;
	color: #fff;
	border-radius: 17.5px;
	padding: 7.5px 30px;
	font-weight: 500;
	line-height: 15px;
}

.sHeader__alert p, .sHeader__alert img {
  -webkit-animation: .3s ease 0s normal forwards 1 fadein;
          animation: .3s ease 0s normal forwards 1 fadein;
}

.sHeader__alert--default {
	background-color: rgba(23, 23, 26, 0.84);	
	margin-right: 30px;
}

.sHeader__alert--saved {
	font-size: 13px;
	line-height: 16px;
	padding-top: 0;
	padding-bottom: 0;
	margin-left: 9px;
}

.sHeader__alert--error {
	background-color: #DF0021;
	box-shadow: 0 0 6px 0 rgba(255,0,37,0.06);
	letter-spacing: 0.2px;
	margin-right: 30px;
}

.dropdown-menu--alerts {
	width: 245px;
	padding: 0;
}

.alerts__container {
	max-height: 290px;
	overflow: auto;
}

.dropdown__item--alerts {
  padding: 17px 27px;
  opacity: 0.5;
  color: #292C36;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.62px;
  line-height: 15px;
  margin-bottom: 2px;
  cursor: default;
	background: transparent!important;
}

.dropdown__item--clear {
  color: #FF8900;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  padding: 17px 27px;
  background: transparent!important;
}

.dropdown__item--alert {
	border-top: 1px solid rgba(41, 44, 54, 0.05);
	padding: 17px 22px 17px 27px;
	color: #292C36;
	font-size: 13px;
	font-weight: 500;
	line-height: 16px;
	cursor: default;
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
}

.sHeader__notification__remove {
	padding: 7px;
	box-sizing: content-box;
}

/*.react-switch-bg {
	background: #FF8900!important;
}
*/
@-webkit-keyframes fadein {
  0% { opacity: 0; }
  35% { opacity: .35; }
  50% { opacity: .5; }
  75% { opacity: .75; }
  100% { opacity: 1; }
}
@keyframes fadein {
  0% { opacity: 0; }
  35% { opacity: .35; }
  50% { opacity: .5; }
  75% { opacity: .75; }
  100% { opacity: 1; }
}
.container-codemirror {
	-webkit-flex-basis: 80%;
	        flex-basis: 80%;
	max-width: 80%;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	width: 100%;
	-webkit-flex: 1 1;
	        flex: 1 1;
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
}

.react-codemirror2 {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	max-height: calc(100% - 63px);
}

.CodeMirror {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	height: 100%;
}

.cm-s-material .CodeMirror-gutters, .cm-s-material.CodeMirror {
	background-color: #292C35;	
}

.cm-s-material .CodeMirror-guttermarker, .cm-s-material .CodeMirror-guttermarker-subtle, .cm-s-material .CodeMirror-linenumber {
	color: rgba(255,255,255,0.1);
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.37px;
	line-height: 23px;
}

.CodeMirror pre {
	font-weight: 500;
	line-height: 23px;
}

.logs {
	background-color: #323640;
	box-shadow: 0 2px 8px 0 rgba(0,0,0,0.08);
	min-height: 63px;
	padding: 0 18px;
	position: static!important;
	-webkit-transform: translate(0, 0)!important;
	        transform: translate(0, 0)!important;
	top: inherit!important;
	bottom: 0;
}

.logs span div {
	height: 26px!important;
}

.logs__expand {
	height: 3.48px;
	width: 26px;
	opacity: 0.1;
	border-radius: 3.5px;
	background-color: #fff;
	position: absolute;
	left: 50%;
	top: 13px;
	margin-left: -13px;
}

.logs__container {
	width: 100%;
}

.logs__menu__item {
	color: #fff;
	opacity: .24;
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 0.58px;
	line-height: 14px;
	padding: 24.5px 14px;
	text-transform: uppercase;
	-webkit-transition: opacity .4s;
	transition: opacity .4s;
}

.logs__menu__item:hover {
	opacity: .5;
}

.logs__menu__item--selected, .logs__menu__item[aria-expanded="true"] {
	opacity: 1;
	font-weight: 600;
}

.logs__menu__item:nth-child(3n).logs__menu__item--selected {
	letter-spacing: .487px!important;
}

.logs__menu__item:nth-child(4n).logs__menu__item--selected {
	letter-spacing: .475px!important;
}

.logs__menu--right .logs__menu__item, .logs__search input, .logs__search input::-webkit-input-placeholder {
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: 0.43px;
	line-height: 16px;
}

.logs__menu--right .logs__menu__item, .logs__search input, .logs__search input:-ms-input-placeholder {
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: 0.43px;
	line-height: 16px;
}

.logs__menu--right .logs__menu__item, .logs__search input, .logs__search input::-ms-input-placeholder {
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: 0.43px;
	line-height: 16px;
}

.logs__menu--right .logs__menu__item, .logs__search input, .logs__search input::placeholder {
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: 0.43px;
	line-height: 16px;
}

.logs__menu--right .logs__menu__item {
	padding: 24.5px 14px!important;
}

.logs__menu__icon {
	margin-right: 10px;
}

.logs__content {
	color: #E6E9EF;
	font-size: 11px;
	letter-spacing: 0.29px;
	line-height: 19px;
	padding: 0 14px 18px;
	overflow: auto;
	height: calc(100% - 69px);
}

.dropdown-menu--save {
	margin: -14.5px 0 -14.5px 14px;
	min-width: 108px;
	text-align: left;
}

.logs__menu__save {
	text-transform: capitalize;
}

.logs__search input {
	background: transparent;
	border: none;
	border-bottom: 2px solid transparent;
	-webkit-transition: all .4s;
	transition: all .4s;
}

.logs__search input:focus {
	width: 138px!important;
	border-bottom: 2px solid rgba(255,255,255,.19);
	-webkit-transition: all .4s;
	transition: all .4s;
	opacity: 1
}

.logs__menu__search--active {
	opacity: 1!important;
}

.codemirror__header {
	width: 100%;
	-webkit-flex: 0 1;
	        flex: 0 1;
	box-shadow: 0 2px 9px 0 rgba(0,0,0,0.08);
	margin-bottom: 9px;
}

.codemirror__header__item {
	padding: 13px 11px;
	background-color: #292C35;
}

.codemirror__header__item:first-child {
	border-left: none;
	border-right: 3px solid rgba(0,0,0,.1);
}

.codemirror__select {
	padding: 6px 17px;
	border-radius: 16px;
	background-color: #fff;
	font-size: 12px;
	font-weight: 600;
	line-height: 15px;
}

.codemirror__icon {
	margin-left: 17px;
}

.merge {
	width: 100%;
	height: calc(100% - 247px);
	-webkit-flex: 1 1;
	        flex: 1 1;
}

.CodeMirror-merge {
	display: none;
	height: 100%;
	width: 100%;
	border: none;
}

.CodeMirror-merge:last-of-type {
	display: -webkit-flex;
	display: flex;
}

.CodeMirror-merge-pane, .CodeMirror-merge, .CodeMirror-merge .CodeMirror {
	height: 100%;
}

.CodeMirror-merge-2pane .CodeMirror-merge-pane {
	width: calc(50% - 3px);
}

.CodeMirror-merge-2pane .CodeMirror-merge-gap {
	width: 3px;
	background-color: rgba(0,0,0,.1);
	border: none;
	overflow: visible;
	margin-top: -9px;
	height: calc(100% + 9px);
}

.CodeMirror-merge-scrolllock-wrap {
	display: none;
}

.CodeMirror-merge-r-chunk-end {
	border-bottom: rgba(203, 99, 99, .07);
}

.CodeMirror-merge-r-chunk-start {
	border-top: rgba(203, 99, 99, .07);
}

.CodeMirror-linebackground {
	background-color: rgba(203, 99, 99, .07);
}

.CodeMirror-merge-pane-rightmost .CodeMirror-linebackground {
	background-color: rgba(138, 187, 106, .08);
}

.CodeMirror-merge-copy, .CodeMirror-merge-copy-reverse {
	color: #FF8900;
	margin-top: 9px;
}

.dropdown-menu--merge {
	padding: 4px 0 0;
	font-size: 12px;
	width: 279px;
	max-height: 260px;
	overflow: auto;
}

.codemirror__storage {
	-webkit-flex: 1 1;
	        flex: 1 1;
	border-radius: 2px;
	background-color: rgba(234,126,0,0.1);
	box-shadow: 0 0 7px 0 rgba(0,0,0,0.12);
	margin: 0 4px;
	padding: 13px;
	color: #FF8900;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.21px;
	line-height: 15px;
}

.codemirror__menu__item {
	padding: 16px 23px;
	border-top: 1px solid rgba(41, 44, 54, 0.1);
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-transition: all .4s;
	transition: all .4s;
}

.codemirror__menu__item:nth-child(1n) {
	border-top: none;
}

.codemirror__menu__item:hover {
	background-color: rgba(41, 44, 54, 0.05);
}

.codemirror__menu__title {
	opacity: 0.5;
	font-size: 11px;
	font-weight: 600;
	line-height: 14px;
	margin-bottom: 6px;
}

.codemirror__menu__desc {
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
}

@media only screen and (min-width: 1500px) {
	.container-codemirror {
	    -webkit-flex-basis: 85%;
	            flex-basis: 85%;
	    max-width: 85%;
	}
}
#popout-content-container {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.ReactTable {
	border: none;
	height: 100%;
}

.ReactTable .rt-table {
	overflow-x: hidden;
}

.Table__name {
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.19px;
	line-height: 16px;
}

.Table__btn {
	border-radius: 2px;
	background-color: rgba(41, 44, 54, 0.05);
	margin-left: 5px;
	font-size: 12px;
	font-weight: 600;
	line-height: 15px;
	padding: 8.5px 16.5px;
}

.Table__btn img {
	margin-right: 9px;
}

.Table__filter {
	width: 46px;
	border: none;
	background: transparent;
	font-size: 12px;
	font-weight: 600;
	line-height: 1;
	-webkit-transition: all .4s;
	transition: all .4s;
}

.Table__filter:focus {
	width: 80px;
	-webkit-transition: all .4s;
	transition: all .4s;
}

.Table__filter::-webkit-input-placeholder {
	color: #292C36;
}

.Table__filter:-ms-input-placeholder {
	color: #292C36;
}

.Table__filter::-ms-input-placeholder {
	color: #292C36;
}

.Table__filter::placeholder {
	color: #292C36;
}

.Table__header {
	padding: 7.5px 8px 7.5px 22px;
}

.ReactTable .rt-thead.-header {
	box-shadow: 0 0 5px 0 rgba(41,44,54,0.04);
	background-color: #333640;
	color: #FFFFFF;
	font-size: 11px;
	font-weight: 600;
	letter-spacing: 0.15px;
	line-height: 14px;
}

.ReactTable .rt-tr-group {
	-webkit-flex: 0 1;
	        flex: 0 1;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
	padding: 11.5px;
}

.ReactTable .rt-th, .ReactTable .rt-td {
	text-align: center;
}

.ReactTable .rt-th:last-of-type, .ReactTable .rt-td:last-of-type {
	padding: 0;
	display: none
}

.ReactTable.-striped .rt-tr.-odd {
	background-color: #fff;
}

.ReactTable.-striped .rt-tr.-even {
	background-color: rgba(0,0,0,.05);
}

.ReactTable .rt-td {
	color: #292C36;
	font-size: 11px;
	line-height: 14px;
	padding: 9px;
}

.Table__prevPageWrapper, .Table__nextPageWrapper {
	padding: 5px 10px;
}

.Table__prevPageWrapper img {
	-webkit-transform: rotate(-180deg);
	        transform: rotate(-180deg);
}

.arrow-disabled {
	opacity: .25;
}

.Table__pageButton {
	color: #292C36;
	font-size: 11px;
	font-weight: 600;
	letter-spacing: 0.15px;
	line-height: 14px;
	padding: 2px 6px;
	margin-left: 3px;
}

.Table__pageButton--active {
	border-radius: 2px;
	background-color: #FF8900;
	color: #fff;
}

.Table__pagination {
	-webkit-flex: 1 1;
	        flex: 1 1;
}

.pagination-bottom {
	padding: 15.5px 12px 15.5px 22px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.Table__items {
	opacity: 0.5;
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
}
#sidebar {
	-webkit-flex-basis: 20%;
	        flex-basis: 20%;
	max-width: 20%;
	background-color: #191A23;
	box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
	padding: 20.5px 0;
	overflow: auto;
	height: 100%;
	overflow-x: hidden;
}

.sidebar__content {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
}

.splitter-layout {
	position: static;
}

.sidebar__scroll {
	overflow: auto;
	height: calc(100% - 25px);
}

.splitter-layout .layout-pane {
	display: -webkit-flex;
	display: flex;
	min-height: 24px;
}

.splitter-layout > .layout-splitter {
	padding: 24px 0;
	width: 100%;
	margin-bottom: 20px;
	position: relative;
	background-color: transparent!important;
}

.splitter-layout > .layout-splitter:after {
	content: '';
	position: absolute;
	left: 50%;
	margin-left: -13px;
	top: 50%;
	margin-top: -1.7px;
	height: 3.48px;
	width: 26px;
	opacity: 0.1;
	border-radius: 3.5px;
	background-color: #fff;
}

.sidebar__header {
	margin-bottom: 10px;
}

.sidebar__title {
	text-transform: uppercase;
	color: rgba(255,255,255,0.25);
	font-size: 11px;
	font-weight: 600;
	letter-spacing: 0.57px;
	line-height: 14px;
	padding: 0 25px;
}

.sidebar__library__searchContent {
	margin-right: 27.5px;
	opacity: .25;
}

.sidebar__library__searchContent--active {
	opacity: 1;
}

.sidebar__library__icon {
	object-fit: contain;
}

#sidebar__library__search {
	background: transparent;
	border: none;
	border-bottom: 2px solid transparent;
	-webkit-transition: all .4s;
	transition: all .4s;
	color: #fff;
	width: 0px!important;
	height: 14px;
}

.sidebar__library__searchContent--active #sidebar__library__search,
#sidebar__library__search:focus {
	width: 82px!important;
	border-bottom: 2px solid rgba(255,255,255,.19);
	-webkit-transition: all .4s;
	transition: all .4s;
	opacity: 1;
	margin-left: 8px;
}

.sidebar__item__content {
	/*padding-left: 24px;*/
	margin-right: 24px;
	/* width: 100%; */
}

.sidebar__item__submenu {
	overflow: hidden;
}

.sidebar__item__hover {
	-webkit-transition: all .4s;
	transition: all .4s;
	width: 100%;
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	-webkit-flex-basis: 100%;
	        flex-basis: 100%;
	padding: 10px 27.5px 10px 24px;
}

.sidebar__item__submenu:nth-child(2n) .sidebar__item__files {
	padding-left: 59px;
}

.sidebar__item__submenu:nth-child(3n) .sidebar__item__files {
	padding-left: 73px;
}

.sidebar__item__submenu:nth-child(4n) .sidebar__item__files {
	padding-left: 87px;
}


.sidebar__item__hover--margin {
	/*margin-left: -24px;*/
}

.sidebar__item__hover:hover {
	background-color: rgba(255,255,255,0.06);
	-webkit-transition: all .4s;
	transition: all .4s;
}

.sidebar__item__hover:hover .sidebar__item__dots {
	opacity: 1;
	-webkit-transition: all .4s;
	transition: all .4s;
}

.sidebar__item__dots {
	opacity: .2;
	-webkit-transition: all .4s;
	transition: all .4s;
}

.sidebar__item__files {
	padding: 6.5px 27.5px 6.5px 44px;
	/*margin-left: -24px;*/
}

.sidebar__item__arrow {
	margin-right: 15px;
	-webkit-transition: all .4s;
	transition: all .4s;
}

.collapsed .sidebar__item__arrow, .collapsing .sidebar__item__arrow {
	-webkit-transform: rotate(-90deg);
	        transform: rotate(-90deg);
	-webkit-transition: all .4s;
	transition: all .4s;
}

.sidebar__item__name {
	color: #fff;
	font-size: 13px;
	line-height: 16px;
	margin-left: 12px;
	width: 80%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.dropdown-details {
	padding: 3px 21px 20px;
	margin-top: 80px;
	min-width: 129px;
	cursor: default;
}

.dropdown-details__content {
	margin-top: 13px;
}

.dropdown-details__text {
	font-size: 13px;
	line-height: 20px;
}

.dropdown-details__text--title {
	font-weight: 600;
}

.sidebar__pip {
	height: 287px;
	width: 300px;
	border-radius: 2px;
	background-color: #fff;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14);
	bottom: 6px;
	right: 9px;
	z-index: 100;
	padding: 14px 22px 28px;
}

.sidebar__pip__header {
	margin-bottom: 14px;
}

.sidebar__pip__name {
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
}

.sidebar__pip__export {
	margin-right: 15.5px;
}

@media only screen and (min-width: 1500px) {
	#sidebar {
	  -webkit-flex-basis: 15%;
	          flex-basis: 15%;
	  max-width: 15%;
	}
}
.login {
	border-radius: 2px;
	background-color: #fff;
	box-shadow: 0 0 7px 0 rgba(0,0,0,0.12);
	padding: 56px 57px 61px;
	max-width: 408px;
}

.login__title {
	font-size: 20px;
	font-weight: 500;
	line-height: 24px;
	margin: 30px 0 37px
}

.login__label {
	font-size: 10px;
	font-weight: 600;
	letter-spacing: 0.45px;
	line-height: 12px;
	text-align: left;
}

.login__error--input .login__label {
	color: #FF0025;
}

.login__error--input .login__input {
	border-color: rgba(255,0,37,0.85);
}

.login__input {
	width: 100%;
	border-radius: 2px;
	background-color: #F3F3F3;
	padding: 13px 17px;
	margin: 5px auto 15px;
	border: none;
	border: 1px solid transparent;
	color: rgba(41, 44, 54, 0.5);
	font-size: 12px;
	line-height: 15px;
}

.btn--login {
	padding-left: 60px;
	padding-right: 60px;
	margin-top: 10px;
}

.login__error {
	color: #721c24;
	background-color: #f8d7da;
	position: relative;
	padding: 13px 17px;
	margin-bottom: 20px;
	border: 1px solid #f5c6cb;
	border-radius: 2px;
	font-size: 12px;
	font-weight: 600;
}
