.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 400;
  animation: .2s ease 0s normal forwards 1 fadein;
}

.modal__fade {
  background-color: rgba(22,23,28,0.85);
  width: 100%;
  z-index: 400;
  top: 0;
  left: 0;
  position: fixed;
}

.modal__close {
  opacity: .2;
  padding: 5px;
  box-sizing: content-box;
}

.modal--session__box {
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  margin-top: -263px;
  z-index: 500;
  max-width: 839px;
  width: 90%;
  position: fixed;
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.02);
  background-color: transparent;
  padding: 0;
}

.modal--session__box__left {
  padding: 14px 13px 69px;
  background-color: #292C36;
}

.modal--session__box__right {
  border-radius: 0 2px 2px 0;
  background-color: #1E2028;
  padding: 26px 0 14px;
}

.modal--session__box__content {
  padding: 20px 47px 0;
}

.modal__logo {
  margin-bottom: 24px;
}

.modal__title {
  color: #fff;
  font-size: 27px;
  font-weight: 600;
  line-height: 32px;
}

.modal__desc {
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  margin: 10px 0 46px;
}

.modal__title--gray {
  color: rgba(255,255,255,0.25);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.62px;
  line-height: 15px;
  margin-bottom: 18px;
}

.modal__btn--session {
  max-width: 162px;
  border-radius: 2px;
  background-color: #fff;
  padding: 14px 23px;
  color: #EA7E00;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 6px;
}

.dropdown__item--modal {
  margin-top: 5px;
}

.dropdown__item--modal .dropdown__item {
  padding: 0 36px 0 23px;
  font-weight: 400;
}

.dropdown__item--templates {
  margin-top: -47px;
  min-width: 244px;
}

.dropdown__item--templates .dropdown__item {
  padding: 15px 24px;
  border-top: 1px solid rgba(41, 44, 54, .05);
  line-height: 16px;
}

.dropdown__item--templates .dropdown__item:first-of-type {
  border: none;
}

.dropdown__title {
  opacity: 0.5;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.62px;
  line-height: 15px;
}

.dropdown__arrow {
  margin-right: 12px;
  transform: rotate(-180deg);
}

.modal__link {
  color: #fff;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 24.5px;
  font-size: 14px;
  text-decoration: none;
  transition: all .5s;
}

/*.modal__link:hover {
  opacity: .75;
  transition: all .8s;
}*/

.modal__link img {
  margin-right: 13px;
}

.modal__title--right {
  padding: 0 30px 0 30px;
}

.modal__used {
  padding: 13px 30px;
  transition: all .4s;
}

.modal__used:hover {
  background-color: #272830;
}

.modal__used__name {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-left: 16px;
}

.modal__select {
  color: #FF8900;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.43px;
  line-height: 16px;
  align-items: flex-end;
  flex: 1 1 auto;
}

.modal__icon__upload {
  margin-right: 8px;
}