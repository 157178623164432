.login {
	border-radius: 2px;
	background-color: #fff;
	box-shadow: 0 0 7px 0 rgba(0,0,0,0.12);
	padding: 56px 57px 61px;
	max-width: 408px;
}

.login__title {
	font-size: 20px;
	font-weight: 500;
	line-height: 24px;
	margin: 30px 0 37px
}

.login__label {
	font-size: 10px;
	font-weight: 600;
	letter-spacing: 0.45px;
	line-height: 12px;
	text-align: left;
}

.login__error--input .login__label {
	color: #FF0025;
}

.login__error--input .login__input {
	border-color: rgba(255,0,37,0.85);
}

.login__input {
	width: 100%;
	border-radius: 2px;
	background-color: #F3F3F3;
	padding: 13px 17px;
	margin: 5px auto 15px;
	border: none;
	border: 1px solid transparent;
	color: rgba(41, 44, 54, 0.5);
	font-size: 12px;
	line-height: 15px;
}

.btn--login {
	padding-left: 60px;
	padding-right: 60px;
	margin-top: 10px;
}

.login__error {
	color: #721c24;
	background-color: #f8d7da;
	position: relative;
	padding: 13px 17px;
	margin-bottom: 20px;
	border: 1px solid #f5c6cb;
	border-radius: 2px;
	font-size: 12px;
	font-weight: 600;
}