#storage, .fade {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 300;
  overflow: auto;
  visibility: hidden;
}

.fade {
  background-color: rgba(22,23,28,0.85);
  opacity: 0;
  transition: all .4s;
}

.storage__content {
  width: 85%;
  max-width: 864px;
  padding: 29px 0 39px;
  background-color: #292C36;
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.12);
  position: fixed;
  right: -100%;
  top: 0;
  z-index: 400;
  color: #fff;
  transition: all .4s ease-in-out;
  /* animation: .4s ease 0s normal forwards 1 slideLeft; */
}

#storage.storage--opened, #storage.storage--opened .fade {
  visibility: visible;
  opacity: 1;
}

.storage--opened .storage__content {
  right: 0;
  transition: all .6s;
}

.storage__close__icon {
  position: absolute;
  left: -105px;
  top: 0;
  padding: 43px 44px;
	transform: rotate(-180deg)
}

.storage__header {
  margin-bottom: 36px;
  padding: 0 50px;
  min-height: 41px;
}

.storage__title {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  opacity: .25;
  transition: all .4s;
}

.storage__breadcrumb:last-child .storage__title {
  opacity: 1;
}

.storage__folders__toggle {
  background-color: rgba(255, 255, 255, .05);
  padding: 12px 14px;
}

.dropdown-menu--breadcrumb {
  padding: 0;
}

.dropdown-menu--breadcrumb .dropdown__item--breadcrumb {
  padding: 11.5px 29px 11.5px 24px!important;
  line-height: 16px;
}

.dropdown__item--breadcrumb:first-child, .dropdown__item--breadcrumb:last-child {
  display: none;
}

.storage__folders__icon {
  margin-left: 15px;
}

.storage__header__right__items, .storage__search {
  margin-left: 26px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 15px;
  color: #fff;
}

.storage__search {
  background-color: transparent;
  border: none;
  margin-left: 0;
  width: 46px;
  line-height: 1;
  transition: all .4s;
}

.storage__search::placeholder {
  color: #fff;
}

.storage__search:focus {
  width: 80px;
}

.storage__header__icon {
  margin-right: 8px;
}

.storage__table--container {
  height: 370px;
  overflow: auto;
}

.storage__table {
  width: 100%;
}

.storage__table tr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  min-height: 57px;
}

.storage__table__selected {
  opacity: 1!important;
}

.storage__table__selected * {
  display: inline-block;
}

.storage__table__selected img {
  margin: 2.5px 5px;
  transform: rotate(-180deg);
}

.storage__table__selected .orderASC {
  transform: rotate(0);
}

.storage__table td {
  padding: 19px 0;
}

.storage__table td:first-child {
  padding-left: 50px;
}

.storage__table td:last-child {
  padding-right: 50px;
}

.storage__table thead td {
  opacity: 0.5;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 14px;
  transition: all .4s;
}

.storage__table thead td:hover {
  opacity: 1;
  transition: all .4s;
}

.storage__table tbody tr {
  background-color: transparent;
  transition: all .4s;
}

.storage__table tbody tr:hover, .tr--selected {
  background-color: rgba(255,255,255,0.06)!important;
  transition: all .4s;
}

.storage__table tbody tr:hover .storage__table__options {
  opacity: 1;
  transition: all .4s;
}

.storage__table tbody td, .storage__rename {
  color: rgba(255, 255, 255, .5);
  font-size: 13px;
  line-height: 16px;
}

.storage__table tbody td:first-child {
  color: #fff
}

.storage__table tbody td:last-child {
  padding: 8px 50px 8px 0;
}

.dropdown-menu--table {
  text-align: left;
  z-index: 400;
}

.dropdown-menu--table .dropdown__item {
  padding: 0 29px 0 24px;
}

.storage__table__options {
  padding: 10px;
  opacity: .5;
}

.storage__breadcrumb__icon {
  margin: 0 15px;
  transform: rotate(-90deg);
  opacity: .25;
}

.storage__breadcrumb:last-child .storage__breadcrumb__icon {
  display: none;
}

.storage__name {
  border-bottom: 1px solid transparent;
}

.storage__rename {
  border: none;
  border-bottom: 1px dashed rgba(255, 255, 255, .2);
  background: transparent;
  color: #fff;
}

.storage__save__text {
  color: #FFFFFF;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 45px;
  margin-left: 50px;
}

.storage__save__buttons {
  align-self: flex-end;
  margin-left: 50px;
}

.storage__save__buttons .btn {
  margin-right: 14px;
}

@media (min-height: 800px) {
  .storage__table--container {
    height: 600px;
  }
}