.modal__box__title {
	color: #FF0025;
	font-size: 19px;
	font-weight: 500;
	line-height: 22px;
}

.modal__box__title--gray {
	color: #292C36;
}

.modal__box__desc {
	color: #8E929F;
	font-size: 14px;
	line-height: 24px;
	margin: 9px 0 28px;
}

.modal__box {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); 
  z-index: 500;
  max-width: 425px;
  width: 70%;
  position: fixed;
  background-color: #fff;
  padding: 21px 0 56px;
}

.modal__close--gray {
	opacity: 1!important;
	margin-right: 21px;
}

.modal__box__content {
	max-width: 300px;
	margin: 24px auto 0;
}

.modal__box__btns .btn {
	padding: 11px 23.5px;
	max-width: 49%;
}

#modal__box__input {
	width: 100%;
	border-radius: 2px;
	background-color: #F3F3F3;
	padding: 13px 17px;
	color: rgba(41, 44, 54, .5);
	font-size: 12px;
	line-height: 15px;
	border: none;
	margin: 19px 0 29px;
}