#popout-content-container {
	display: flex;
	flex-direction: column;
}

.ReactTable {
	border: none;
	height: 100%;
}

.ReactTable .rt-table {
	overflow-x: hidden;
}

.Table__name {
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.19px;
	line-height: 16px;
}

.Table__btn {
	border-radius: 2px;
	background-color: rgba(41, 44, 54, 0.05);
	margin-left: 5px;
	font-size: 12px;
	font-weight: 600;
	line-height: 15px;
	padding: 8.5px 16.5px;
}

.Table__btn img {
	margin-right: 9px;
}

.Table__filter {
	width: 46px;
	border: none;
	background: transparent;
	font-size: 12px;
	font-weight: 600;
	line-height: 1;
	transition: all .4s;
}

.Table__filter:focus {
	width: 80px;
	transition: all .4s;
}

.Table__filter::placeholder {
	color: #292C36;
}

.Table__header {
	padding: 7.5px 8px 7.5px 22px;
}

.ReactTable .rt-thead.-header {
	box-shadow: 0 0 5px 0 rgba(41,44,54,0.04);
	background-color: #333640;
	color: #FFFFFF;
	font-size: 11px;
	font-weight: 600;
	letter-spacing: 0.15px;
	line-height: 14px;
}

.ReactTable .rt-tr-group {
	flex: 0;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
	padding: 11.5px;
}

.ReactTable .rt-th, .ReactTable .rt-td {
	text-align: center;
}

.ReactTable .rt-th:last-of-type, .ReactTable .rt-td:last-of-type {
	padding: 0;
	display: none
}

.ReactTable.-striped .rt-tr.-odd {
	background-color: #fff;
}

.ReactTable.-striped .rt-tr.-even {
	background-color: rgba(0,0,0,.05);
}

.ReactTable .rt-td {
	color: #292C36;
	font-size: 11px;
	line-height: 14px;
	padding: 9px;
}

.Table__prevPageWrapper, .Table__nextPageWrapper {
	padding: 5px 10px;
}

.Table__prevPageWrapper img {
	transform: rotate(-180deg);
}

.arrow-disabled {
	opacity: .25;
}

.Table__pageButton {
	color: #292C36;
	font-size: 11px;
	font-weight: 600;
	letter-spacing: 0.15px;
	line-height: 14px;
	padding: 2px 6px;
	margin-left: 3px;
}

.Table__pageButton--active {
	border-radius: 2px;
	background-color: #FF8900;
	color: #fff;
}

.Table__pagination {
	flex: 1;
}

.pagination-bottom {
	padding: 15.5px 12px 15.5px 22px;
	display: flex;
	justify-content: space-between;
}

.Table__items {
	opacity: 0.5;
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
}