.container-codemirror {
	flex-basis: 80%;
	max-width: 80%;
	display: flex;
	flex-direction: column;
	width: 100%;
	flex: 1;
	flex-wrap: nowrap;
}

.react-codemirror2 {
	flex: 1 1 auto;
	max-height: calc(100% - 63px);
}

.CodeMirror {
	flex: 1 1 auto;
	height: 100%;
}

.cm-s-material .CodeMirror-gutters, .cm-s-material.CodeMirror {
	background-color: #292C35;	
}

.cm-s-material .CodeMirror-guttermarker, .cm-s-material .CodeMirror-guttermarker-subtle, .cm-s-material .CodeMirror-linenumber {
	color: rgba(255,255,255,0.1);
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.37px;
	line-height: 23px;
}

.CodeMirror pre {
	font-weight: 500;
	line-height: 23px;
}

.logs {
	background-color: #323640;
	box-shadow: 0 2px 8px 0 rgba(0,0,0,0.08);
	min-height: 63px;
	padding: 0 18px;
	position: static!important;
	transform: translate(0, 0)!important;
	top: inherit!important;
	bottom: 0;
}

.logs span div {
	height: 26px!important;
}

.logs__expand {
	height: 3.48px;
	width: 26px;
	opacity: 0.1;
	border-radius: 3.5px;
	background-color: #fff;
	position: absolute;
	left: 50%;
	top: 13px;
	margin-left: -13px;
}

.logs__container {
	width: 100%;
}

.logs__menu__item {
	color: #fff;
	opacity: .24;
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 0.58px;
	line-height: 14px;
	padding: 24.5px 14px;
	text-transform: uppercase;
	transition: opacity .4s;
}

.logs__menu__item:hover {
	opacity: .5;
}

.logs__menu__item--selected, .logs__menu__item[aria-expanded="true"] {
	opacity: 1;
	font-weight: 600;
}

.logs__menu__item:nth-child(3n).logs__menu__item--selected {
	letter-spacing: .487px!important;
}

.logs__menu__item:nth-child(4n).logs__menu__item--selected {
	letter-spacing: .475px!important;
}

.logs__menu--right .logs__menu__item, .logs__search input, .logs__search input::placeholder {
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: 0.43px;
	line-height: 16px;
}

.logs__menu--right .logs__menu__item {
	padding: 24.5px 14px!important;
}

.logs__menu__icon {
	margin-right: 10px;
}

.logs__content {
	color: #E6E9EF;
	font-size: 11px;
	letter-spacing: 0.29px;
	line-height: 19px;
	padding: 0 14px 18px;
	overflow: auto;
	height: calc(100% - 69px);
}

.dropdown-menu--save {
	margin: -14.5px 0 -14.5px 14px;
	min-width: 108px;
	text-align: left;
}

.logs__menu__save {
	text-transform: capitalize;
}

.logs__search input {
	background: transparent;
	border: none;
	border-bottom: 2px solid transparent;
	transition: all .4s;
}

.logs__search input:focus {
	width: 138px!important;
	border-bottom: 2px solid rgba(255,255,255,.19);
	transition: all .4s;
	opacity: 1
}

.logs__menu__search--active {
	opacity: 1!important;
}

.codemirror__header {
	width: 100%;
	flex: 0;
	box-shadow: 0 2px 9px 0 rgba(0,0,0,0.08);
	margin-bottom: 9px;
}

.codemirror__header__item {
	padding: 13px 11px;
	background-color: #292C35;
}

.codemirror__header__item:first-child {
	border-left: none;
	border-right: 3px solid rgba(0,0,0,.1);
}

.codemirror__select {
	padding: 6px 17px;
	border-radius: 16px;
	background-color: #fff;
	font-size: 12px;
	font-weight: 600;
	line-height: 15px;
}

.codemirror__icon {
	margin-left: 17px;
}

.merge {
	width: 100%;
	height: calc(100% - 247px);
	flex: 1;
}

.CodeMirror-merge {
	display: none;
	height: 100%;
	width: 100%;
	border: none;
}

.CodeMirror-merge:last-of-type {
	display: flex;
}

.CodeMirror-merge-pane, .CodeMirror-merge, .CodeMirror-merge .CodeMirror {
	height: 100%;
}

.CodeMirror-merge-2pane .CodeMirror-merge-pane {
	width: calc(50% - 3px);
}

.CodeMirror-merge-2pane .CodeMirror-merge-gap {
	width: 3px;
	background-color: rgba(0,0,0,.1);
	border: none;
	overflow: visible;
	margin-top: -9px;
	height: calc(100% + 9px);
}

.CodeMirror-merge-scrolllock-wrap {
	display: none;
}

.CodeMirror-merge-r-chunk-end {
	border-bottom: rgba(203, 99, 99, .07);
}

.CodeMirror-merge-r-chunk-start {
	border-top: rgba(203, 99, 99, .07);
}

.CodeMirror-linebackground {
	background-color: rgba(203, 99, 99, .07);
}

.CodeMirror-merge-pane-rightmost .CodeMirror-linebackground {
	background-color: rgba(138, 187, 106, .08);
}

.CodeMirror-merge-copy, .CodeMirror-merge-copy-reverse {
	color: #FF8900;
	margin-top: 9px;
}

.dropdown-menu--merge {
	padding: 4px 0 0;
	font-size: 12px;
	width: 279px;
	max-height: 260px;
	overflow: auto;
}

.codemirror__storage {
	flex: 1;
	border-radius: 2px;
	background-color: rgba(234,126,0,0.1);
	box-shadow: 0 0 7px 0 rgba(0,0,0,0.12);
	margin: 0 4px;
	padding: 13px;
	color: #FF8900;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.21px;
	line-height: 15px;
}

.codemirror__menu__item {
	padding: 16px 23px;
	border-top: 1px solid rgba(41, 44, 54, 0.1);
	flex-direction: column;
	transition: all .4s;
}

.codemirror__menu__item:nth-child(1n) {
	border-top: none;
}

.codemirror__menu__item:hover {
	background-color: rgba(41, 44, 54, 0.05);
}

.codemirror__menu__title {
	opacity: 0.5;
	font-size: 11px;
	font-weight: 600;
	line-height: 14px;
	margin-bottom: 6px;
}

.codemirror__menu__desc {
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
}

@media only screen and (min-width: 1500px) {
	.container-codemirror {
	    flex-basis: 85%;
	    max-width: 85%;
	}
}